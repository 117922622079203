/**
 * Copyright © Veeam Software Group GmbH.
 */

import { observer } from 'mobx-react-lite';
import React from 'react';
import {
    Form,
    FormLayout,
    FieldLayout,
    StackView,
    STACK_DIRECTION,
    STACK_GAP,
    Checkbox,
    CONTROL_SIZE,
    LinkButton,
    NoLabelGroup,
    Combobox,
    Radiobutton,
    NumberInput,
    usePageWizardStore,
    FormValidator,
    isValid,
    constants,
} from '@veeam-vspc/components';
import { Vb365BackupJobSchedulePolicy } from '@veeam-vspc/models/rest';

import type { WizardStep, WizardStepData, FormErrors } from '@veeam-vspc/components';
import type { Vb365BackupJob } from '@veeam-vspc/models/rest';

import { TimeField } from 'components/controls/TimeField';
import { StepLayout } from 'components/layouts/StepLayout';
import { useLang } from 'views/providers/LangProvider/hooks';
import { useJobWizardStore } from '../../stores';
import { getPeriodicallyReadableValues, isPeriodicScheduleApplicable } from '../../../../helpers';
import { ConfigureSchedule, ConfigureBackupWindow } from './components';

import type { JobWizardStore } from '../../stores';
import type { LangsServiceBase } from 'core/services/langs/interfaces';

export const stepValidate = (data: Vb365BackupJobSchedulePolicy): FormErrors<Vb365BackupJobSchedulePolicy> => {
    const validator = new FormValidator(data);

    if (data.retryEnabled) {
        validator
            .validate('retryNumber')
            .number()
            .max(777)
            .min(1);

        validator
            .validate('retryWaitInterval')
            .number()
            .max(999)
            .min(1);
    }

    return validator.result();
};

export const getScheduleStep = (lang: LangsServiceBase, store: JobWizardStore): WizardStep<Vb365BackupJob> => ({
    title: lang.SCHEDULE,
    isHidden: () => store.isScheduleHidden,
    render: data => <ScheduleStep {...data} />,
    validate: () => {
        const validationResult = isValid(stepValidate, store.data.schedulePolicy);

        return validationResult;
    },
});

const { SchedulePolicyTypeEnum, PeriodicallyEveryEnum, DailyTypeEnum } = Vb365BackupJobSchedulePolicy;

const ScheduleStep = observer(({ validationState }: WizardStepData<Vb365BackupJob>) => {
    const lang = useLang();
    const wizardStore = useJobWizardStore();
    const wizardContext = usePageWizardStore();

    const handleDataChange = (data: Vb365BackupJobSchedulePolicy) => {
        wizardStore.updateSchedule({
            ...data,
            backupWindowSettings: wizardStore.data.schedulePolicy.backupWindowSettings,
            periodicallyWindowSettings: wizardStore.data.schedulePolicy.periodicallyWindowSettings,
            periodicallyOffsetMinutes: wizardStore.data.schedulePolicy.periodicallyOffsetMinutes,
            periodicallyWindowEnabled: data.scheduleEnabled &&
                data.schedulePolicyType === SchedulePolicyTypeEnum.Periodically &&
                [
                    PeriodicallyEveryEnum.Hours1,
                    PeriodicallyEveryEnum.Hours2,
                    PeriodicallyEveryEnum.Hours4,
                    PeriodicallyEveryEnum.Hours8,
                ].includes(data.periodicallyEvery),
        });
    };

    return (
        <StepLayout
            title={lang.SCHEDULE}
            description={lang.CHOOSE_WHEN_YOU_WANT_BACKUP_JOB}
        >
            <Form
                value={wizardStore.data.schedulePolicy}
                validationState={validationState}
                validate={stepValidate}
                onChange={handleDataChange}
            >

                <FormLayout inlineLabel>
                    <NoLabelGroup
                        content={(
                            <Checkbox name='scheduleEnabled'>
                                {lang.RUN_THE_JOB_AUTOMATICALLY}
                            </Checkbox>
                        )}
                        subContent={(
                            <StackView gap={STACK_GAP.m} direction={STACK_DIRECTION.column}>
                                <FormLayout direction={STACK_DIRECTION.row}>
                                    <FieldLayout
                                        disabled={!wizardStore.data.schedulePolicy.scheduleEnabled}
                                    >
                                        <Radiobutton
                                            name='schedulePolicyType'
                                            value={SchedulePolicyTypeEnum.Daily}
                                        >
                                            {lang.DAILY_AT_THIS_TIME}
                                        </Radiobutton>
                                    </FieldLayout>

                                    <FieldLayout
                                        disabled={!wizardStore.data.schedulePolicy.scheduleEnabled ||
                                                wizardStore.data.schedulePolicy.schedulePolicyType !== SchedulePolicyTypeEnum.Daily}
                                    >
                                        <TimeField
                                            name='dailyTime'
                                            value={wizardStore.data.schedulePolicy.dailyTime}
                                            shortFormat
                                            minutesInterval={1}
                                            utc
                                        />
                                    </FieldLayout>

                                    <FieldLayout
                                        disabled={!wizardStore.data.schedulePolicy.scheduleEnabled ||
                                                wizardStore.data.schedulePolicy.schedulePolicyType !== SchedulePolicyTypeEnum.Daily}
                                    >
                                        <Combobox
                                            name='dailyType'
                                            value={wizardStore.data.schedulePolicy.dailyType}
                                            onChange={value => wizardStore.updateSchedule({ ...wizardStore.data.schedulePolicy, dailyType: value })}
                                            data={[
                                                { value: DailyTypeEnum.Everyday, text: lang.EVERYDAY },
                                                { value: DailyTypeEnum.Workdays, text: lang.WORKDAYS },
                                                { value: DailyTypeEnum.Weekends, text: lang.WEEKENDS },
                                                { value: DailyTypeEnum.Monday, text: lang.MONDAY },
                                                { value: DailyTypeEnum.Tuesday, text: lang.TUESDAY },
                                                { value: DailyTypeEnum.Wednesday, text: lang.WEDNESDAY },
                                                { value: DailyTypeEnum.Thursday, text: lang.THURSDAY },
                                                { value: DailyTypeEnum.Friday, text: lang.FRIDAY },
                                                { value: DailyTypeEnum.Saturday, text: lang.SATURDAY },
                                                { value: DailyTypeEnum.Sunday, text: lang.SUNDAY },
                                            ]}
                                            valueGetter={x => x.value}
                                            textGetter={x => x.text}
                                        />
                                    </FieldLayout>
                                </FormLayout>

                                <FormLayout direction={STACK_DIRECTION.row}>
                                    <FieldLayout
                                        disabled={!wizardStore.data.schedulePolicy.scheduleEnabled}
                                    >
                                        <Radiobutton
                                            name='schedulePolicyType'
                                            value={SchedulePolicyTypeEnum.Periodically}
                                        >
                                            {lang.PERIODICALLY_EVERY}
                                        </Radiobutton>
                                    </FieldLayout>

                                    <Combobox
                                        name='periodicallyEvery'
                                        value={wizardStore.data.schedulePolicy.periodicallyEvery}
                                        onChange={value => wizardStore.updateSchedule({
                                            ...wizardStore.data.schedulePolicy,
                                            periodicallyEvery: value,
                                        })}
                                        data={[
                                            {
                                                value: PeriodicallyEveryEnum.Minutes5,
                                                text: getPeriodicallyReadableValues(lang)[PeriodicallyEveryEnum.Minutes5],
                                            },
                                            {
                                                value: PeriodicallyEveryEnum.Minutes10,
                                                text: getPeriodicallyReadableValues(lang)[PeriodicallyEveryEnum.Minutes10],
                                            },
                                            {
                                                value: PeriodicallyEveryEnum.Minutes15,
                                                text: getPeriodicallyReadableValues(lang)[PeriodicallyEveryEnum.Minutes15],
                                            },
                                            {
                                                value: PeriodicallyEveryEnum.Minutes30,
                                                text: getPeriodicallyReadableValues(lang)[PeriodicallyEveryEnum.Minutes30],
                                            },
                                            {
                                                value: PeriodicallyEveryEnum.Hours1,
                                                text: getPeriodicallyReadableValues(lang)[PeriodicallyEveryEnum.Hours1],
                                            },
                                            {
                                                value: PeriodicallyEveryEnum.Hours2,
                                                text: getPeriodicallyReadableValues(lang)[PeriodicallyEveryEnum.Hours2],
                                            },
                                            {
                                                value: PeriodicallyEveryEnum.Hours4,
                                                text: getPeriodicallyReadableValues(lang)[PeriodicallyEveryEnum.Hours4],
                                            },
                                            {
                                                value: PeriodicallyEveryEnum.Hours8,
                                                text: getPeriodicallyReadableValues(lang)[PeriodicallyEveryEnum.Hours8],
                                            },
                                        ]}
                                        valueGetter={x => x.value}
                                        textGetter={x => x.text}
                                        size={CONTROL_SIZE.xs}
                                        disabled={!wizardStore.data.schedulePolicy.scheduleEnabled ||
                                            wizardStore.data.schedulePolicy.schedulePolicyType !== SchedulePolicyTypeEnum.Periodically}
                                    />

                                    <LinkButton
                                        onClick={() => wizardContext.openPanel(hide => <ConfigureSchedule hidePanel={hide} />)}
                                        disabled={!isPeriodicScheduleApplicable(wizardStore.data.schedulePolicy)}
                                        style={{ width: 'fit-content' }}
                                    >
                                        {lang.SET_SCHEDULE}
                                    </LinkButton>
                                </FormLayout>
                            </StackView>
                        )}
                    />

                    <NoLabelGroup
                        content={(
                            <Checkbox name='retryEnabled'>
                                {lang.AUTOMATIC_RETRY}
                            </Checkbox>
                        )}
                        subContent={(
                            <FormLayout inlineLabel style={{ marginLeft: `-${constants.SPACING_S}` }}>
                                <FieldLayout
                                    label={`${lang.RETRY_FAILED_OBJECTS}:`}
                                    disabled={!wizardStore.data.schedulePolicy.retryEnabled}
                                    inlineLabel
                                >
                                    <NumberInput
                                        name='retryNumber'
                                        value={wizardStore.data.schedulePolicy.retryNumber}
                                        inlineLabel
                                        suffix={lang.TIMES}
                                        minValue={1}
                                        maxValue={777}
                                    />
                                </FieldLayout>

                                <FieldLayout
                                    label={`${lang.WAIT_BEFORE_RETRY}:`}
                                    disabled={!wizardStore.data.schedulePolicy.retryEnabled}
                                    inlineLabel
                                >
                                    <NumberInput
                                        name='retryWaitInterval'
                                        value={wizardStore.data.schedulePolicy.retryWaitInterval}
                                        inlineLabel
                                        suffix={lang.MINUTES.toLowerCase()}
                                        minValue={1}
                                        maxValue={999}
                                    />
                                </FieldLayout>
                            </FormLayout>
                        )}
                    />

                    <NoLabelGroup
                        content={(
                            <Checkbox name='backupWindowEnabled'>
                                {lang.TERMINATE_JOB_IF_IT_EXCEEDS_ALLOWED_BACKUP_WINDOW}
                            </Checkbox>
                        )}
                    />

                    <NoLabelGroup
                        content={(
                            <LinkButton
                                onClick={() => wizardContext.openPanel(hide => <ConfigureBackupWindow hidePanel={hide} />)}
                                disabled={!wizardStore.data.schedulePolicy.backupWindowEnabled}
                                style={{ width: 'fit-content' }}
                            >
                                {lang.CONFIGURE_BACKUP_WINDOW}
                            </LinkButton>
                        )}
                    />
                </FormLayout>
            </Form>
        </StepLayout>
    );
});
