/**
 * Copyright © Veeam Software Group GmbH.
 */

import React from 'react';
import { ActionLinkColumn, ColumnTexts, MessageBox, ParsedText, TextColumn } from '@veeam-vspc/components';
import { Vb365Job } from '@veeam-vspc/models/rest';

import type { CellRendererProps } from '@veeam-vspc/components';

import { getStatusIconSrc } from 'core/utils';
import { useLang } from 'views/providers/LangProvider/hooks';

export const JobStatusColumn: React.FC<CellRendererProps<Vb365Job>> = ({ rowData }) => {
    const { lastStatus, lastStatusDetails } = rowData;
    const lang = useLang();

    const isLastStatusValid = lastStatus && lastStatus !== Vb365Job.LastStatusEnum.Unknown;

    const iconSrc = getStatusIconSrc(lastStatus);

    return (
        <ActionLinkColumn
            renderContent={() => (
                <TextColumn style={{ color: 'inherit' }}>
                    {isLastStatusValid ? lastStatus : ColumnTexts.DashSymbol}
                </TextColumn>
            )}
            isLink={[Vb365Job.LastStatusEnum.Warning, Vb365Job.LastStatusEnum.Failed].includes(lastStatus)}
            contentIconBefore={isLastStatusValid ? iconSrc : ''}
            isExtMode={true}
            renderModal={({ deactivate }) => (
                <MessageBox
                    header={lang.JOB_STATUS}
                    onRequestClose={deactivate}
                    actions={[
                        { text: lang.OK, onClick: deactivate },
                    ]}
                    iconSrc={iconSrc}
                >
                    <ParsedText textOrConfig={lastStatusDetails} />
                </MessageBox>
            )}
        />
    );
};
