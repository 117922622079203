/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import {
    Form,
    FormValidator,
    TextInput,
    Textarea,
    CONTROL_SIZE,
    FormLayout,
    useExternalFormApi,
    isValid,
    Combobox,
    createSearchableControl,
} from '@veeam-vspc/components';
import { capitalize, formatStr } from '@veeam-vspc/core';
import { UserTitleRepresentation } from '@veeam-vspc/models/web-controllers';

import type { WizardStep, WizardStepData, ExternalFormApi } from '@veeam-vspc/components';

import { StepLayout } from 'components/layouts/StepLayout';
import { useLang } from 'views/providers/LangProvider/hooks';
import { core } from 'core/core-module';
import { extjsRegExpValidate, extjsValidate } from 'core/utils/validators';
import { trimTextFields } from 'core/utils/string-helpers';
import { useCompanyWizardStore } from '../../hooks';

import type { CompanyWizardStore } from '../../stores';
import type { LangsServiceBase } from 'core/services/langs/interfaces/langs-service-base';
import type { CountryModel, IsExistsNameOrAliasParams } from 'core/interfaces';
import type { CompanyData } from '../../interfaces';

interface StepData {
    formApi?: ExternalFormApi<CompanyData>;
}

const isCompanyNameDisabled = (): boolean => {
    const { portalUser } = core.portalService.getPortalData();

    return portalUser.isCompanyOwner();
};

const formValidate = (data: CompanyData, lang: any) => {
    const validator = new FormValidator(data);

    const defaultNameLightValidator = (value: string): string =>
        extjsRegExpValidate(value, RCOP.utils.Validator.defaultNameLight, lang.SPECIFIED_VALUE_IS_INVALID);

    validator.validate('name')
        .string()
        .required()
        .maxLength(128)
        .check(defaultNameLightValidator);

    validator.validate('alias')
        .string()
        .maxLength(32)
        .check(v => extjsValidate(v, RCOP.utils.Validator.tenantName));

    validator.validate('taxId')
        .string()
        .maxLength(100)
        .check(defaultNameLightValidator);

    validator.validate('firstName')
        .string()
        .maxLength(128)
        .check(v => extjsValidate(v, RCOP.utils.Validator.vacFirstAndLastName));

    validator.validate('lastName')
        .string()
        .maxLength(128)
        .check(v => extjsValidate(v, RCOP.utils.Validator.vacFirstAndLastName));

    validator.validate('emailAddress')
        .string()
        .maxLength(128)
        .check(v => extjsValidate(v, RCOP.utils.Validator.email));

    validator.validate('telephone')
        .string()
        .maxLength(128);

    validator.validate('companyId')
        .string()
        .maxLength(128)
        .check(defaultNameLightValidator);

    validator.validate('zipCode')
        .string()
        .maxLength(128)
        .check(defaultNameLightValidator);

    validator.validate('domain')
        .string()
        .maxLength(128);

    validator.validate('notes')
        .string()
        .maxLength(512);

    return validator.result();
};

const stepValidate = async(data: CompanyData, lang: any, isEdit: boolean, { formApi }: StepData, wizardStore: CompanyWizardStore) => {
    // trim text fields
    trimTextFields(data, ['name', 'alias', 'taxId', 'title', 'firstName', 'lastName', 'emailAddress', 'telephone', 'companyId', 'zipCode', 'domain']);
    formApi?.setValue(data);
    const { portalUser } = core.portalService.getPortalData();

    if (isValid(value => formValidate(value, lang), data)) {
        const checkData: IsExistsNameOrAliasParams = {
            companyId: data.id,
            name: data.name,
            alias: data.alias ?? null,
            cloudAgentUid: data.cloudConnectAgentUid,
        };

        const { isNameExists, isAliasExists, name: companyName } = await wizardStore.isExistsNameOrAliasCache.load(checkData);

        if (formApi) {
            if (isNameExists) {
                if (portalUser.isServiceProviderGlobalOrUsualAdmin()) {
                    formApi.setExternalErrors({ name: lang.CANNOT_USE_THIS_COMPANY_NAME });
                } else {
                    formApi.setExternalErrors({ name: formatStr(lang.COMPANY_NAME_MATCHES_THE_ALIAS, companyName) });
                }
            }

            if (isAliasExists) {
                formApi.setExternalErrors({ alias: lang.CANNOT_USE_THIS_ALIAS });
            }
        }

        return !isNameExists && !isAliasExists;
    }

    return false;
};

export const getCompanyInfoStep = (lang: LangsServiceBase, store: CompanyWizardStore): WizardStep<CompanyData> => ({
    title: lang.COMPANY_INFO,
    validate: ({ data, isEdit, stepData }) => stepValidate(data, lang, isEdit, stepData, store),
    render: stepData => <CompanyInfoStep {...stepData} />,
});

const CompanyInfoStep = observer((wizardStepData: WizardStepData<CompanyData>) => {
    const { data, validationState, onDataChange } = wizardStepData;
    const stepData = wizardStepData.stepData as StepData;
    const lang = useLang();
    const formApi = useExternalFormApi<CompanyData>();
    const defaultFieldWidth = CONTROL_SIZE.l;
    const wizardStore = useCompanyWizardStore();

    const isUSA = (countryId: number): boolean => countryId === 1;

    useEffect(() => {
        stepData.formApi = formApi;

        return () => {
            delete stepData.formApi;
        };
    }, []);

    useEffect(() => {
        wizardStore.countriesCache.load();
        wizardStore.usaStatesCache.load();
    }, []);

    return (
        <StepLayout
            title={capitalize(lang.COMPANY_INFO)}
            description={lang.SPECIFY_COMPANY_NAME}
        >
            <Form
                value={data}
                validate={(data: CompanyData) => formValidate(data, lang)}
                validationState={validationState}
                externalFormApi={formApi}
                onChange={onDataChange}
            >
                <FormLayout inlineLabel>
                    <TextInput
                        name={'name'}
                        label={capitalize(lang.COMPANY_NAME, true)}
                        size={defaultFieldWidth}
                        disabled={isCompanyNameDisabled()}
                    />

                    <TextInput
                        name={'alias'}
                        label={lang.LOGIN_ALIAS}
                        size={defaultFieldWidth}
                    />

                    <TextInput
                        name={'taxId'}
                        label={lang.TAX_ID}
                        size={defaultFieldWidth}
                    />

                    <Combobox
                        name={'title'}
                        label={lang.TITLE}
                        data={wizardStore.userTitles || []}
                        valueGetter={item => item.id}
                        textGetter={item => item.name}
                        size={defaultFieldWidth}
                        controlRenderer={createSearchableControl()}
                        emptyValue={UserTitleRepresentation.Unknown}
                    />

                    <TextInput
                        name={'firstName'}
                        label={lang.FIRST_NAME}
                        size={defaultFieldWidth}
                    />

                    <TextInput
                        name={'lastName'}
                        label={lang.LAST_NAME}
                        size={defaultFieldWidth}
                    />

                    <TextInput
                        name={'emailAddress'}
                        label={lang.EMAIL_ADDRESS}
                        size={defaultFieldWidth}
                    />

                    <TextInput
                        name={'telephone'}
                        label={lang.TELEPHONE}
                        size={defaultFieldWidth}
                    />

                    <Combobox <CountryModel, number>
                        name={'country'}
                        label={lang.COUNTRY}
                        data={wizardStore.countriesCache.value || []}
                        valueGetter={item => item.id}
                        textGetter={item => `${item.name} (${item.code})`}
                        size={defaultFieldWidth}
                        onChange={(newValue) => {
                            if (!isUSA(newValue)) {
                                formApi.setValue('usState', 0);
                            }
                        }}
                        controlRenderer={createSearchableControl()}
                    />

                    <Combobox
                        name={'usState'}
                        label={lang.STATE}
                        data={wizardStore.usaStatesCache.value || []}
                        valueGetter={item => item.id}
                        textGetter={item => item.name}
                        disabled={!isUSA(data.country)}
                        size={defaultFieldWidth}
                        controlRenderer={createSearchableControl()}
                    />

                    <TextInput
                        name={'companyId'}
                        label={lang.VEEAM_TENANT_ID}
                        size={defaultFieldWidth}
                        disabled={data.isMappedToPulseTenant}
                    />

                    <TextInput
                        name={'zipCode'}
                        label={lang.ZIP_CODE}
                        size={defaultFieldWidth}
                    />

                    <TextInput
                        name={'domain'}
                        label={lang.WEB_SITE}
                        size={defaultFieldWidth}
                    />

                    <Textarea
                        name={'notes'}
                        label={lang.ADDITIONAL_NOTES}
                        size={CONTROL_SIZE.l}
                        rows={2}
                    />
                </FormLayout>
            </Form>
        </StepLayout>
    );
});
