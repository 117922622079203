/**
 * Copyright © Veeam Software Group GmbH.
 */

import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { SizeColumn, TextColumn } from '@veeam-vspc/components/src/Grid/columns';
import {
    ACTION_VIEW,
    STACK_DIRECTION,
    SPACE_FILL,
    STACK_GAP,
    StackView,
    Form,
    Checkbox,
    NumberInput,
    FormValidator,
    useExternalFormApi,
    ValidationState,
    SortingMode,
    NoteBar,
    NOTEBAR_STATUS,
    Text,
} from '@veeam-vspc/components';
import { capitalize, deepCopy } from '@veeam-vspc/core';

import type { BaseSuccessRequestResponse } from '@veeam-vspc/core';
import type { WizardStepData, GridStore } from '@veeam-vspc/components';

import { AdvancedGrid } from 'views/components/AdvancedGrid';
import { SidePanelForm } from 'components/layouts/SidePanelForm';
import { useLang } from 'views/providers/LangProvider/hooks';
import { useCompanyWizardStore } from '../../../../hooks';
import { getWanAcceleratorColumn } from '../../utils';
import { ActionsToolbar } from './components/ActionsToolbar';
import { compareVersion } from 'core/utils/version-helpers';

import type { AvailableBackupResource } from 'core/interfaces/available-backup-resource';
import type { CompanyWizardStore } from '../../../../stores';
import type { QuotaModel } from 'core/interfaces/quota-model';
import type { BackupResource, CompanyData } from '../../../../interfaces';

export interface BackupResourcesProps extends WizardStepData<CompanyData> {
    hidePanel: () => void;
}

const MIN_BACKUP_PROTECTION_PERIOD = 1;
const MAX_BACKUP_PROTECTION_PERIOD = 99;

const getMaxBackupProtectionPeriod = (availableBackupResources: AvailableBackupResource[]): number => {
    if (availableBackupResources[0]?.availableInsiderProtectionQuota > 0) {
        return availableBackupResources[0].availableInsiderProtectionQuota;
    }

    return MAX_BACKUP_PROTECTION_PERIOD;
};

const isBackupProtectionVisible = (wizardStore: CompanyWizardStore, ccAgentUid: string) => {
    const cloudConnectAgentVersion = wizardStore.cloudConnectAgentsCache.value?.find(agent => agent.agentGuid === ccAgentUid)?.serverVersion;
    if (cloudConnectAgentVersion) {
        const minVersion = '9.5.0.1536';
        if (compareVersion(cloudConnectAgentVersion, minVersion) < 0) {
            return false;
        }
    } else {
        return false;
    }

    const availableBackupResources = wizardStore.availableBackupResourcesCache.value;
    if (availableBackupResources
        && availableBackupResources[0]
        && availableBackupResources[0].availableInsiderProtectionQuota === 0) {
        return false;
    }

    return true;
};

const formValidate = (data: CompanyData, maxBackupProtectionPeriod: number) => {
    const validator = new FormValidator(data);

    if (data.backupProtectionEnabled) {
        validator.validate('backupProtectionPeriod')
            .number()
            .required()
            .min(MIN_BACKUP_PROTECTION_PERIOD)
            .max(maxBackupProtectionPeriod);
    }

    return validator.result();
};

export const BackupResources = observer((props: BackupResourcesProps) => {
    const lang = useLang();
    const { hidePanel, onDataChange } = props;
    const gridApi = useRef<GridStore<BackupResource, void, void>>();
    const formApi = useExternalFormApi<CompanyData>();
    const wizardStore = useCompanyWizardStore();

    const onGridItemsChanged = (items: BackupResource[]) => {
        const isObjectStorage: boolean = items.some(item => wizardStore.availableBackupResourcesCache.value
            ?.find(res => res.uniqueUid === item.uniqueUid)?.isObjectStorage);
        setIsObjectStorage(isObjectStorage);
        if (isObjectStorage) {
            formApi.setValue('backupProtectionEnabled', false);
        }
    };

    const [isObjectStorage, setIsObjectStorage] = useState(false);
    const [data, setData] = useState(deepCopy(props.data));
    const [validationState] = useState(new ValidationState());
    const [maxBackupProtectionPeriod, setMaxBackupProtectionPeriod] = useState(MAX_BACKUP_PROTECTION_PERIOD);

    const quotaColumn = (quota: QuotaModel, getter: (quota: QuotaModel) => number) => {
        const result = quota.quotasIsUnlimited ? lang.UNLIMITED : `${getter(quota)}`;
        return <TextColumn>{result}</TextColumn>;
    };

    useEffect(() => {
        wizardStore.availableBackupResourcesCache.load({
            cloudConnectAgentUid: data.cloudConnectAgentUid,
            companyId: data.id,
        })
            .then((resources) => {
                setMaxBackupProtectionPeriod(getMaxBackupProtectionPeriod(resources));
                onGridItemsChanged(data.quotas);
            });
        wizardStore.wanAcceleratorsCache.load({ cloudConnectAgentUid: data.cloudConnectAgentUid });
    }, []);

    const sortQuotaFn = (fieldName: string) => (rowData1: BackupResource, rowData2: BackupResource) => {
        const unlimited = -1;
        const quota1: number = rowData1.quota[fieldName];
        const quota2: number = rowData2.quota[fieldName];

        if (quota2 === unlimited) {
            return -1;
        }
        if (quota1 === unlimited) {
            return 1;
        }

        return quota1 - quota2;
    };

    return (
        <SidePanelForm
            onRequestClose={hidePanel}
            title={capitalize(lang.BACKUP_RESOURCES)}
            description={lang.SELECT_BACKUP_REPOSITORY_AND_ASSIGN}
            actions={[
                {
                    text: lang.APPLY,
                    onClick: () => {
                        if (!isBackupProtectionVisible(wizardStore, data.cloudConnectAgentUid) || formApi.validate()) {
                            Object.assign(props.data, data);
                            onDataChange(data);
                            hidePanel();
                        } else {
                            validationState.markAsForce();
                        }
                    },
                },
                {
                    text: lang.CANCEL,
                    view: ACTION_VIEW.secondary,
                    onClick: hidePanel,
                },
            ]}
        >
            <StackView direction={STACK_DIRECTION.column} spaceFill={SPACE_FILL.all} gap={STACK_GAP.s}>
                <AdvancedGrid <BackupResource, void, void>
                    defaultColumnWidth={100}
                    columns={[
                        { field: 'cloudConnectResourceName', title: capitalize(lang.BACKUP_REPOSITORY) },
                        { field: 'cloudRepositoryFriendlyName', title: lang.CLOUD_REPOSITORY },
                        {
                            field: 'quota.storageGb',
                            title: lang.QUOTA,
                            cell: ({ rowData }) => (
                                <SizeColumn value={rowData.quota.storageGb} startUnit={'G'} />
                            ),
                            sorterFn: sortQuotaFn('storageGb'),
                        },
                        {
                            field: 'quota.vms',
                            title: capitalize(lang.VMS_QUOTA),
                            cell: ({ rowData }) => (quotaColumn(rowData.quota, (quota => quota.vms))),
                            sorterFn: sortQuotaFn('vms'),
                            hidden: true,
                        },
                        {
                            field: 'quota.workstations',
                            title: capitalize(lang.WORKSTATIONS_QUOTA),
                            cell: ({ rowData }) => (quotaColumn(rowData.quota, (quota => quota.workstations))),
                            sorterFn: sortQuotaFn('workstations'),
                            hidden: true,
                        },
                        {
                            field: 'quota.servers',
                            title: capitalize(lang.SERVERS_QUOTA),
                            cell: ({ rowData }) => (quotaColumn(rowData.quota, (quota => quota.servers))),
                            sorterFn: sortQuotaFn('servers'),
                            hidden: true,
                        },
                        {
                            field: 'isDefault',
                            title: lang.DEFAULT_REPOSITORY,
                            cell: ({ rowData }) => (<TextColumn>{rowData.isDefault ? lang.YES : null}</TextColumn>),
                        },
                        getWanAcceleratorColumn(wizardStore, lang),
                    ]}
                    data={() => Promise.resolve({
                        data: data.quotas,
                        meta: {
                            pagingInfo: {
                                total: data.quotas?.length,
                            },
                        },
                    } as BaseSuccessRequestResponse<BackupResource[]>)}
                    stateId={'CompanyWizard.Services.BackupResources'}
                    disablePagination={true}
                    disableAutoUpdate={true}
                    selection={{
                        field: 'cloudRepositoryFriendlyName',
                        multiple: false,
                        checkbox: false,
                    }}
                    toolbars={[
                        () => (
                            <ActionsToolbar
                                quotas={data.quotas}
                                cloudConnectAgentUid={data.cloudConnectAgentUid}
                                companyId={data.id}
                                onQuotasUpdated={onGridItemsChanged}
                            />
                        ),
                    ]}
                    sortingMode={SortingMode.Local}
                    api={gridApi}
                />

                {isBackupProtectionVisible(wizardStore, data.cloudConnectAgentUid) && (
                    <Form <CompanyData>
                        value={data}
                        validate={v => formValidate(v, maxBackupProtectionPeriod)}
                        onChange={(v) => {
                            setData({ ...v });
                        }}
                        externalFormApi={formApi}
                        validationState={validationState}
                    >
                        <StackView direction={STACK_DIRECTION.column} gap={STACK_GAP.s}>
                            <StackView direction={STACK_DIRECTION.row} gap={STACK_GAP.s}>
                                <Checkbox
                                    name={'backupProtectionEnabled'}
                                    disabled={isObjectStorage}
                                >
                                    {lang.PROTECT_DELETED_BACKUP_FILES_FOR}
                                </Checkbox>

                                <NumberInput
                                    name={'backupProtectionPeriod'}
                                    disabled={!data.backupProtectionEnabled || isObjectStorage}
                                    suffix={lang.DAYS.toLowerCase()}
                                    allowDecimal={false}
                                    minValue={MIN_BACKUP_PROTECTION_PERIOD}
                                    maxValue={maxBackupProtectionPeriod}
                                />
                            </StackView>

                            <NoteBar status={NOTEBAR_STATUS.info}>
                                <Text>{lang.OPTION_TO_PROTECT}</Text>
                            </NoteBar>
                        </StackView>
                    </Form>
                )}
            </StackView>
        </SidePanelForm>
    );
});
