/**
 * Copyright © Veeam Software Group GmbH.
 */

import { plural, capitalize } from '@veeam-vspc/core';
import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import {
    CheckboxKit,
    WizardSummary,
    dataSizeToString,
    ParsedText,
    GridKit,
    TextColumn,
    GridHeightMode,
} from '@veeam-vspc/components';

import type { ResellerCloudConnectAgent } from '@veeam-vspc/models/web-controllers';
import type { WizardStep, WizardStepData } from '@veeam-vspc/components';

import { useLang } from 'views/providers/LangProvider/hooks';
import { StepLayout } from 'components/layouts/StepLayout';
import { CompanyMfaStatuses } from 'core/enums';
import { useResellerWizardStore } from '../../hooks';
import { getBandwidthUnits } from 'views/components/wizard/utils';

import type { ResellerData } from '../../interfaces';
import type { LangsServiceBase } from 'core/services/langs/interfaces';

interface Props extends WizardStepData<ResellerData> {
    allowSendEmail: boolean;
}

const companiesQuota = (items: ResellerCloudConnectAgent[], lang: LangsServiceBase) => (
    <GridKit <ResellerCloudConnectAgent, ResellerCloudConnectAgent>
        style={{ marginLeft: -8, marginTop: -16 }}
        data={items}
        columns={[
            {
                field: 'cloudConnectAgentName',
                title: lang.SITE,
            },
            {
                field: 'status',
                title: lang.COMPANIES,
                cell: ({ rowData }) => <TextColumn>{rowData.tenantQuota === -1 ? lang.UNLIMITED : rowData.tenantQuota}</TextColumn>,
            },
        ]}
        height={GridHeightMode.Native}
    />
);

export const getSummaryStep = (lang: any, allowSendEmail: boolean): WizardStep<ResellerData> => ({
    title: lang.SUMMARY,
    render: data => <SummaryStep {...data} allowSendEmail={allowSendEmail} />,
});

export const SummaryStep = observer(({ data, isEdit, onDataChange, allowSendEmail }: Props) => {
    const lang = useLang();
    const wizardStore = useResellerWizardStore();

    useEffect(() => {
        wizardStore.countriesCache.load();
        wizardStore.usaStatesCache.load();
        wizardStore.availableVboServersCache.load();
        wizardStore.availableVboBackupRepositoryCache.load();
    }, []);

    if (!data.emailAddress || !allowSendEmail) {
        wizardStore.isRequiredSendEmail = false;
    }

    return (
        <StepLayout
            title={lang.SUMMARY}
            description={lang.REVIEW_AND_COPY_MSG}
        >
            <WizardSummary
                fieldsets={[
                    {
                        title: lang.RESELLER_INFO,
                        fields: [
                            {
                                label: `${lang.RESELLER_NAME}:`,
                                value: data.name,
                            },
                            {
                                label: `${lang.LOGIN_ALIAS}:`,
                                value: data.alias,
                            },
                            {
                                label: `${lang.VEEAM_PRO_PARTNER_ID}:`,
                                value: data.proPartnerId,
                            },
                            {
                                label: `${lang.TAX_ID}:`,
                                value: data.taxId,
                            },
                            {
                                label: `${lang.TITLE}:`,
                                value: data.title
                                    ? wizardStore.userTitles.find(item => item.id === data.title)?.name
                                    : '',
                            },
                            {
                                label: `${lang.FIRST_NAME}:`,
                                value: data.firstName,
                            },

                            {
                                label: `${lang.LAST_NAME}:`,
                                value: data.lastName,
                            },
                            {
                                label: `${lang.EMAIL_ADDRESS}:`,
                                value: data.emailAddress,
                            },
                            {
                                label: `${lang.TELEPHONE}:`,
                                value: data.telephone,
                            },
                            {
                                label: `${lang.COUNTRY}:`,
                                value: data.country && data.country >= 0 && wizardStore.countriesCache.value?.length > 0
                                    ? wizardStore.countriesCache?.value.find(item => item.id === data.country).name
                                    : '',
                            },
                            {
                                label: `${lang.STATE}:`,
                                value: data.usState && data.usState >= 0 && wizardStore.usaStatesCache.value?.length > 0
                                    ? wizardStore.usaStatesCache.value?.find(item => item.id === data.usState).name
                                    : '',
                                hidden: !data.usState,
                            },
                            {
                                label: `${lang.VEEAM_TENANT_ID}:`,
                                value: data.companyId,
                            },
                            {
                                label: `${lang.ZIP_CODE}:`,
                                value: data.zipCode,
                            },
                            {
                                label: `${lang.WEB_SITE}:`,
                                value: data.domain,
                            },
                            {
                                label: `${lang.NOTES}:`,
                                value: data.notes,
                            },
                        ],
                    }, {
                        title: lang.COMPANIES_QUOTA,
                        renderBody: companiesQuota(data.cloudConnectAgents, lang),
                    }, {
                        title: lang.BACKUP_AGENTS_QUOTA,
                        fields: [
                            {
                                label: `${lang.WORKSTATION_AGENTS}:`,
                                value: wizardStore.workstationAgentsEnabled
                                    ? (data.isWorkstationAgentsQuotaUnlimited ? lang.UNLIMITED : data.workstationAgents)
                                    : lang.DISABLED,
                            },
                            {
                                label: `${lang.SERVER_AGENTS}:`,
                                value: wizardStore.serverAgentsEnabled
                                    ? (data.isServerAgentsQuotaUnlimited ? lang.UNLIMITED : data.serverAgents)
                                    : lang.DISABLED,
                            },
                        ],
                    }, data.cloudBackupResources.length > 0 ? {
                        title: lang.CLOUD_CONNECT_BACKUP_RESOURCES,
                        renderBody: (
                            <ParsedText
                                textOrConfig={data.cloudBackupResources.map((res) => {
                                    const quotaSize = res.quota.isStorageQuotaUnlimited
                                        ? lang.UNLIMITED
                                        : dataSizeToString(res.quota.storageGb, 0, 'G');
                                    return `${res.cloudRepositoryFriendlyName} (${lang.QUOTA.toLowerCase()} ${quotaSize})`;
                                })}
                            />
                        ),
                    } : null, data.cloudReplicationResources.length > 0 ? {
                        title: lang.CLOUD_CONNECT_REPLICATION_RESOURCES,
                        renderBody: (
                            <ParsedText
                                textOrConfig={data.cloudReplicationResources.map(item => (
                                    `${item.cloudConnectResourceName} (${lang.MAX_TENANTS_PER_PLAN.toLowerCase()}: ${item.tenantsPerPlan})`
                                ))}
                            />
                        ),
                    } : null, data.cloudVcdResources.length > 0 ? {
                        title: lang.VMWARE_CLOUD_DIRECTOR_RESOURCES,
                        renderBody: (
                            <ParsedText
                                textOrConfig={data.cloudVcdResources.map(item => item.cloudConnectResourceName)}
                            />
                        ),
                    } : null, wizardStore.microsoft365ResourcesEnabled && data.vboResources?.length > 0 ? {
                        title: lang.MICROSOFT_365_MANAGED_BACKUP,
                        fields: [
                            {
                                label: `${lang.BACKUP_SERVER}:`,
                                value: data.vboResources?.map(item => wizardStore.availableVboServersCache.value
                                    ?.find(availableServer => availableServer.instanceUid === item.vboInstanceUid)?.name).join(', '),
                            },
                            {
                                label: `${lang.BACKUP_REPOSITORY}:`,
                                value: data.vboResources?.reduce(
                                    (prevValue, curRepository) =>
                                        prevValue.concat(...curRepository.vboRepositories
                                            .map(repository => wizardStore.availableVboBackupRepositoryCache.value
                                                ?.find(availableRepository => availableRepository.instanceUid === repository.repositoryUid)
                                                ?.name)
                                        ),
                                    []).join(', '),
                                hidden: data.vboResources?.reduce(
                                    (prevValue, curRepository) => prevValue + curRepository.vboRepositories.length, 0
                                ) === 0,
                            },
                        ],
                    } : null, {
                        title: capitalize(lang.PUBLIC_CLOUD, true),
                        fields: [
                            {
                                label: `${lang.VEEAM_BACKUP_FOR_PUBLIC_CLOUD_DEPLOYMENT}:`,
                                value: data.publicCloudEnabled ? lang.ON : lang.OFF,
                            },
                        ],
                    }, {
                        title: capitalize(lang.DATA_TRANSFER_OUT, true),
                        fields: [
                            {
                                label: `${lang.DATA_TRANSFER_OUT_QUOTA}:`,
                                value: wizardStore.dataTransferGbEnabled ? dataSizeToString(data.dataTransferGb, 0, 'G') : lang.UNLIMITED,
                            },
                        ],
                    }, {
                        title: lang.ADVANCED_SETTINGS,
                        fields: [
                            {
                                label: `${lang.WAN_ACCELERATION}:`,
                                value: data.wanAccelerationEnabled ? lang.ENABLED : lang.DISABLED,
                            },
                            {
                                label: `${lang.ALLOW_KEEPING_DELETED_BACKUP_FILES}:`,
                                value: wizardStore.hasInsiderProtection ? plural(data.insiderProtectionQuota, lang.DAY) : lang.DISABLED,
                            },
                            {
                                label: `${lang.FILE_LEVEL_RESTORE}:`,
                                value: data.isFileLevelRestoreEnabled ? lang.ENABLED : lang.DISABLED,
                            },
                        ],
                    }, {
                        title: lang.BANDWIDTH,
                        fields: [
                            {
                                label: `${lang.THROTTLING}:`,
                                value: data.isUnlimitedBandwidth
                                    ? lang.UNLIMITED
                                    : (
                                        data.throttlingEnabled
                                            ? `${data.throttlingValue} ${getBandwidthUnits(lang)
                                                .find(item => item.value === data.throttlingUnit).name}`
                                            : lang.NOT_ASSIGNED
                                    ),
                            },
                            {
                                label: `${lang.MAX_CONCURRENT_TASKS}:`,
                                value: data.isUnlimitedBandwidth ? lang.UNLIMITED : `${data.maxConcurrentTasks} ${lang.PER_COMPANY}`,
                            },
                        ],
                    }, {
                        title: capitalize(lang.MULTI_FACTOR_AUTHENTICATION.toLowerCase(), true),
                        fields: [
                            {
                                label: `${lang.MFA_STATUS}:`,
                                value: data.mfaPolicyStatus === CompanyMfaStatuses.Enabled ? lang.ENFORCED : lang.NOT_ENFORCED,
                            },
                        ],
                    }, {
                        title: capitalize(lang.LICENSE_MANAGEMENT, true),
                        fields: [
                            {
                                label: `${lang.LICENSE_CREATION}:`,
                                value: data.pulseLicenseCreation ? lang.ENABLED : lang.DISABLED,
                            },
                            {
                                label: `${lang.CONTRACT_ID}:`,
                                value: data.pulseContractId,
                                hidden: !data.pulseLicenseCreation,
                            },
                            {
                                label: `${lang.POINTS_QUOTA}:`,
                                value: data.pulsePointsLimit ? data.pulsePointsLimitValue : lang.UNLIMITED,
                                hidden: !data.pulseLicenseCreation,
                            },
                        ],
                    },
                ].filter(item => item !== null)}
            />

            {!isEdit && (
                <CheckboxKit
                    checked={wizardStore.isRequiredSendEmail}
                    onChange={value => wizardStore.isRequiredSendEmail = value}
                    disabled={!data.emailAddress || !allowSendEmail}
                >
                    {lang.SEND_WELCOME_EMAIL_NOTIFICATION}
                </CheckboxKit>
            )}
        </StepLayout>
    );
});
